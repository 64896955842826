.return-refund-section {
    padding: 20px 0;
    background-color: #f9f9f9;
  }
  
  .return-refund-section h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .return-refund-section .section-intro {
    margin-bottom: 30px;
  }
  
  .return-refund-section ul {
    list-style: none;
    padding-left: 0;
  }
  
  .return-refund-section ul li {
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;
    color: #555;
  }
  
  .return-refund-section ul li::before {
    content: "✔";
    position: absolute;
    left: 0;
    color: var(--themeColor);
  }
  
  .return-refund-section a.highlight {
    color: var(--themeColor);
    font-weight: 600;
    text-decoration: underline;
  }
  
  .policy-section {
    margin-bottom: 40px;
  }
  
  .policy-section h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--themeColor);
    margin-bottom: 15px;
  }
  
  .policy-section p {
    color: #666;
    line-height: 1.8;
  }
  
  .policy-section ul li {
    font-size: 1rem;
  }
  
  .footer-text {
    padding: 20px 0;
    background: #333;
    color: #fff;
  }
  
  .footer-text p {
    font-size: 0.9rem;
    margin: 0;
  }
  