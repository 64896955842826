/* External CSS for EventDetails */
.event-details {
    padding: 1rem 0;
}

.event-details .container {
    max-width: 1200px;
    margin: 0 auto;
}

.event-details .event-header {
    text-align: center;
    margin-bottom: 2rem;
}

.event-details .event-header h2 {
    text-transform: capitalize;
    font-size: 2.5rem;
    font-weight: 600;
}

.event-details .event-header h3 {
    color: #6c757d;
    font-size: 1.8rem;
}

.event-details .event-image {
    text-align: center;
    margin-bottom: 2rem;
}

.event-details .event-image img {
    max-width: 50%;
    height: 350px;
}

.event-details .event-content {
    font-size: 1rem;
    line-height: 1.8;
    color: #333;
    margin-top: 1.5rem;
}

.event-content {
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Container for event cards */
.event-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Adds space between cards */
    justify-content: center;
  }
  
  /* Single event card */
  .event-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    width: calc(25% - 16px); /* 4 cards per row with spacing */
  }
  
  .event-card:hover {
    transform: scale(1.05);
  }
  
  /* Image styling */
  .event-card-img {
    width: 100%;
    height: 250px; /* Fixed height for consistency */
    object-fit: cover;
    border-radius: 10px;
  }
  
  /* Responsive grid */
  @media (max-width: 1024px) {
    .event-card {
      width: calc(50% - 16px); /* 2 cards per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .event-card {
      width: 100%; /* 1 card per row on small screens */
    }
  }
  