

/* Container to center the form */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f4f4f4;
    padding: 20px;
}

/* Box where the form will be */
.reset-password-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

/* Heading for the page */
.reset-password-box h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Success message after password reset */
.success-message {
    font-size: 16px;
    color: #4caf50;
    text-align: center;
}

/* Form styles */
.reset-password-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Input field container */
.password-input-container {
    position: relative;
}
/* 
input[type="password"],
input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
} */

.show-password-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    color: #F58634;
    cursor: pointer;
    font-size: 14px;
}

/* Submit button */
.submit-btn {
    padding: 10px;
    background-color:#F58634;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #F58634;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .reset-password-box {
        width: 90%;
        padding: 15px;
    }

    .reset-password-box h2 {
        font-size: 20px;
    }
}