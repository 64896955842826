.forget-password {
    padding: 2rem 0;
    background: var(--backgroundColor);
}

.otp-input {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
.otp-input input {
    width: 50px;
    height: 50px;
    margin: 0 8px;
    text-align: center;
    font-size: 1.5rem;
    border: 2px solid var(--themeColor);
    border-radius: 12px;
    background-color: var(--themeColor);
    color: #ffffff;
    transition: all 0.3s ease;
}
.otp-input input:focus {
    box-shadow: none;
    outline: none;
}
.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otp-input input[type=number] {
    -moz-appearance: textfield;
}
button:disabled {
    background: #cccccc;
    border-color: #999999;
    color: #666666;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}
#timer {
    font-size: 1rem;
    color: #A556F6;
    font-weight: 500;
    margin-left: 10px;
}
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}
.expired {
    animation: pulse 2s infinite;
    color: #ff4444;
}
.resend-text {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #b0b0b0;
}
.resend-link {
    color: #6665F1;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}
.resend-link:hover {
    color: #A556F6;
    text-decoration: underline;
}
#email {
    color: #A556F6;
    font-weight: 500;
}

.modal .modal-dialog {
    max-width: 500px;
    margin: auto;
  }
  
  .modal .modal-content {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  @media only screen and (max-width:768px) {
    .modal .modal-dialog {
        max-width: 350px;
        margin: auto;
      }

      .otp-input input{
        width: 40px;
        height: 40px;
        margin: 0px 2px;
      }
      .otp-input{
        margin-bottom: 1rem;
      }
      
  }