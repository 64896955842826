.thank-you-container {
    background-color: #f9f9f9;
    padding: 4rem 0;
  }
  
  .icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle-icon {
    width: 100px;
    height: 100px;
    background: linear-gradient(135deg, #f0932b, #eb4d4b);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: pulse 2s infinite ease-in-out;
  }
  
  .check-icon {
    font-size: 2.5rem;
    color: white;
    font-weight: bold;
  }
  
  .particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }
  
  .particle {
    width: 8px;
    height: 8px;
    background-color: #ffa726;
    border-radius: 50%;
    position: absolute;
    animation: fly 2s infinite ease-in-out;
  }
  
  .particle-1 { top: -10px; left: 50%; transform: translateX(-50%); }
  .particle-2 { top: 10%; left: 80%; }
  .particle-3 { top: 20%; left: 90%; }
  .particle-4 { top: 50%; left: 110%; }
  .particle-5 { top: 80%; left: 90%; }
  .particle-6 { top: 90%; left: 50%; transform: translateX(-50%); }
  .particle-7 { top: 80%; left: 10%; }
  .particle-8 { top: 50%; left: -10%; }
  .particle-9 { top: 20%; left: 10%; }
  .particle-10 { top: 10%; left: 20%; }
  .particle-11 { top: -10px; left: 50%; transform: translateX(-50%); }
  .particle-12 { top: 80%; left: 80%; }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  @keyframes fly {
    0% {
      opacity: 0;
      transform: scale(0.8) translateY(0);
    }
    50% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    100% {
      opacity: 0;
      transform: scale(0.8) translateY(0);
    }
  }
  
  h1 {
    font-size: 1.8rem;
    color: #333;
  }
  
  button {
    font-size: 1rem;
    padding: 10px 20px;
  }
  