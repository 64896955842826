.faq{
    padding: 2rem 0;
}
.faq-heading-content {
  text-align: center;
  padding: 2rem;
  background: var(--themeColor);
  color: white;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.faq-heading-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.faq-heading-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
  color: #f0f0f0;
}
.faq-heading-content a {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
  color: #f0f0f0;
}

.accordion-item {
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.accordion-button {
  border: none;
  background: #f8f9fa;
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 10px;
  transition: background 0.3s ease, color 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background-color: var(--themeColor);
  color: white;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-body {
  padding: 1.5rem;
  background: #f8f9fa;
  color: #555;
  line-height: 1.8;
  font-size: 1rem;
  border-radius: 0 0 10px 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-heading-content h1 {
    font-size: 2.5rem;
  }

  .faq-heading-content p {
    font-size: 1rem;
  }

  .accordion-button {
    font-size: 1rem;
    padding: 0.8rem;
  }

  .accordion-body {
    font-size: 0.9rem;
  }
}
