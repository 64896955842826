.page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f4f4f9;
    text-align: center;
    font-family: "Arial", sans-serif;
    color: #333;
}

.content {
    max-width: 600px;
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #F58634;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.pathname {
    font-weight: bold;
    color: #007bff;
}

.home-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #F58634;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #0056b3;
}