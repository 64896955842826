.product-tabs {
  background-color: var(--whiteBackground);
}

.tabs {
  border: 1px solid var(--themeColor);
  max-width: fit-content;
  max-height: fit-content;
  border-radius: 2rem;
  width: 55%;
  padding: 0.5rem;
}

.tab-button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  border-radius: 2rem;
}

.tab-button.active {
  background-color: var(--themeColor);
  color: white;
}

.tab-button:hover {
  background-color: var(--themeColor);
  color: white;
}

.tab-content {
  margin-top: 10px;
  margin-bottom: 20px;
}

.productName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 135px;
}

.tabsection {
  width: 40%;
}

.product_heading_tab {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .pruduct-spacing {
    margin-bottom: 2rem;
  }
  .product_heading_tab {
    display: grid;
    text-align: center;
  }

  .tabs {
    max-width: fit-content;
    width: 100%;
    font-size: 12px;
    margin: auto;
  }

  .product_heading_tab h2 {
    font-size: 18px;
  }

  .tabsection {
    width: 100%;
  }

  .tab-button {
    padding: 3px 5px;
  }
}
