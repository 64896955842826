.footer-container {
  background-color: #1c1c1c;
  color: #f2f2f2;
  padding: 40px 20px;
  font-family: "Poppins", sans-serif;
}

.footer-main {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--themeColor);
  text-transform: uppercase;
}

.footer-section p,
.footer-section ul {
  font-size: 14px;
  margin: 0;
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #f2f2f2;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: var(--themeColor);
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: white;
  transition: transform 0.3s, background-color 0.3s;
}

.social-icons a:hover {
  transform: scale(1.2);
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
}

.footer-bottom ul {
  display: flex;
  gap: 20px;
}

.footer-bottom ul li a {
  color: #f2f2f2;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-bottom ul li a:hover {
  color: var(--themeColor);
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer-bottom ul{
    display: none;
  } 
  .footer-container{
    padding: 40px 0 5px;
  }
}
